import React, { useEffect, useState } from "react";
import p from "./image/1.png";
import ice1 from "./image/ice/1.png";
import ice2 from "./image/ice/2.png";
import ice3 from "./image/ice/3.png";
import ice4 from "./image/ice/4.png";
import ice5 from "./image/ice/5.png";
import ice6 from "./image/ice/6.png";
import ice7 from "./image/ice/7.png";
import ice8 from "./image/ice/8.png";
import ice9 from "./image/ice/9.png";
import ice10 from "./image/ice/10.png";
import ice11 from "./image/ice/11.png";
import ice12 from "./image/ice/12.png";
import ice13 from "./image/ice/13.png";
import lock from "./image/lock.png";
import lock1 from "./image/lock1.png";
import lv3 from "./image/000.png";
import useSound from "use-sound";
import mydaisy from "./image/mydaisy_1.mp3";
const start = "08/08/2023";
export default function Home() {
  const [pass, setpass] = useState();
  const [pass2, setpass2] = useState();
  const [pass3, setpass3] = useState();
  const [openlv1, setopenlv1] = useState("");
  const [openlv2, setopenlv2] = useState("");
  const [openlv3, setopenlv3] = useState("");
  const [date, setDate] = useState();
  const [timeDiff, setTimeDiff] = useState({
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [play] = useSound(mydaisy, { loop: true, volume: 0.1 });
  useEffect(() => {
    // เล่นเสียงเมื่อคอมโพเนนต์โหลด
    // play();
    // ไม่จำเป็นต้องทำอะไรใน return เพราะไม่มีการหยุดเสียงโดยอัตโนมัติ
  }, [play]);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const startDate = new Date(start);
      const diffTime = now - startDate;

      let diffDate = new Date(diffTime);

      setTimeDiff({
        years: diffDate.getUTCFullYear() - 1970,
        months: diffDate.getUTCMonth(),
        days: diffDate.getUTCDate() - 1,
        hours: diffDate.getUTCHours(),
        minutes: diffDate.getUTCMinutes(),
        seconds: diffDate.getUTCSeconds(),
      });

      setDate(now);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const Passckeck = () => {
    try {
      if (
        pass === "08082024" ||
        pass === "08/08/2024" ||
        pass === "08/08/2566" ||
        pass === "08082566"
      ) {
        setopenlv1("openlv1");
      }
    } catch (error) {}
  };

  const Passckeck2 = () => {
    try {
      if (
        pass2 === "บุ๊ค" ||
        pass2 === "loveyou" ||
        pass2 === "mydaisy" ||
        pass2 === "รักเค้ามั้ย"
      ) {
        setopenlv2("openlv2");
      }
    } catch (error) {}
  };

  const Passckeck3 = () => {
    try {
      if (pass3 === "ต้นทานตะวันแคระ") {
        setopenlv3("openlv3");
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="bg-sky-200 h-10 flex justify-center items-center p-0 top-0 z-20 fixed w-full">
        <p className="lg:text-xl text-xs my-2 text-pink-500 text-wrap">
          เราใช้ชีวิตด้วยกันมา{" "}
        </p>
        <p className="lg:text-xl text-xs my-2 text-pink-500 text-wrap lg:ml-2">
          {timeDiff.years} ปี {timeDiff.months} เดือน {timeDiff.days} วัน{" "}
          {timeDiff.hours} ชั่วโมง {timeDiff.minutes} นาที {timeDiff.seconds}{" "}
          วินาที
        </p>

        <p className="lg:text-xl text-xs my-2 text-pink-500 text-wrap lg:ml-2">
          แล้วนะ !!!
        </p>
      </div>
      <div className=" bg-pink-300/50 flex flex-col  items-center p-0">
        <div class="grid grid-cols-2 md:grid-cols-4 gap-4 mt-20 m-10">
          <div class="grid gap-4">
            <div>
              <img class="h-auto max-w-full rounded-lg" src={ice1} alt="" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={ice2} alt="" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={ice3} alt="" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={ice4} alt="" />
            </div>
          </div>
          <div class="grid gap-4">
            <div>
              <img class="h-auto max-w-full rounded-lg" src={ice5} alt="" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={ice6} alt="" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={ice7} alt="" />
            </div>
          </div>
          <div class="grid gap-4">
            <div>
              <img class="h-auto max-w-full rounded-lg" src={ice8} alt="" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={ice9} alt="" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={ice10} alt="" />
            </div>
          </div>
          <div class="grid gap-4">
            <div>
              <img class="h-auto max-w-full rounded-lg" src={ice11} alt="" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={ice12} alt="" />
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg" src={ice13} alt="" />
            </div>
          </div>
        </div>
        {openlv1 === "openlv1" ? (
          <>
            {" "}
            {openlv2 === "openlv2" ? (
              <>
                {/* <div className="m-10 ">
            <img
              class="h-auto max-w-full rounded-lg animate-jump"
              src={lv2}
              alt=""
            />
          </div> */}
                {openlv3 === "openlv3" ? (
                  <>
                    <div className="m-10 ">
                      <img
                        class="h-auto max-w-full rounded-lg animate-jump"
                        src={lv3}
                        alt=""
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="my-10 flex justify-center flex-col items-center">
                      <div className="m-10 ">
                        <img
                          class="h-auto max-w-full rounded-lg animate-jump"
                          src={lock1}
                          alt=""
                        />
                      </div>
                      <p className="md:text-3xl text-xl text-center">
                        รหัสลับอีกขั้นเพื่อปลดล็อกดูของขวัญ คำใบ้คือ
                        เธอชอบมากๆๆๆ มีสีขาวกับสีดำ
                      </p>
                      <div className="flex mt-10 ">
                        <label className="input input-bordered flex items-center gap-2 ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            className="h-4 w-4 opacity-70"
                          >
                            <path
                              fillRule="evenodd"
                              d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <input
                            type="password"
                            className="grow"
                            value={pass3}
                            onChange={(e) => setpass3(e.target.value)}
                          />
                        </label>
                        <button
                          onClick={Passckeck3}
                          className="btn btn-outline btn-secondary ml-2"
                        >
                          ยืนยัน
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="my-10 flex justify-center flex-col items-center">
                  <div className="m-10 ">
                    <img
                      class="h-auto max-w-full rounded-lg animate-jump"
                      src={lock1}
                      alt=""
                    />
                  </div>
                  <p className="md:text-3xl text-xl text-center">
                    รหัสลับอีกขั้นเพื่อปลดล็อกดูของขวัญ คำใบ้คือ รัก
                  </p>
                  <div className="flex mt-10 ">
                    <label className="input input-bordered flex items-center gap-2 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        className="h-4 w-4 opacity-70"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <input
                        type="password"
                        className="grow"
                        value={pass2}
                        onChange={(e) => setpass2(e.target.value)}
                      />
                    </label>
                    <button
                      onClick={Passckeck2}
                      className="btn btn-outline btn-secondary ml-2"
                    >
                      ยืนยัน
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="my-10 flex justify-center flex-col items-center">
              <div className="m-10 ">
                <img
                  class="h-auto max-w-full rounded-lg animate-jump"
                  src={lock}
                  alt=""
                />
              </div>
              <p className="md:text-3xl text-xl text-center">
                รหัสลับเพื่อปลดล็อกดูของขวัญ
              </p>
              <div className="flex mt-10 ">
                <label className="input input-bordered flex items-center gap-2 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="h-4 w-4 opacity-70"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <input
                    type="password"
                    className="grow"
                    value={pass}
                    onChange={(e) => setpass(e.target.value)}
                  />
                </label>
                <button
                  onClick={Passckeck}
                  className="btn btn-outline btn-secondary ml-2"
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
